import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as braze from "@braze/web-sdk";

braze.initialize('09776401-1819-4bdf-bd98-be06f8ad74b8', {
  baseUrl: "sondheim.braze.com",
  allowUserSuppliedJavascript: true, //for IAM
  minimumIntervalBetweenTriggerActionsInSeconds: 5,
  enableLogging: true
});

// optionally show all in-app messages without custom handling
braze.automaticallyShowInAppMessages();

braze.changeUser('mt-react');

// `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
braze.openSession();
//console.log("Skipped braze.openSession()");

//console.log("isPushSupported:" + braze.isPushSupported())
//console.log("PushPermissionGranted:" + braze.isPushPermissionGranted())
//console.log("isPushBlocked:" + braze.isPushBlocked())

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


